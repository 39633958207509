import * as React from "react";

const useLocalStorage = (key, initialValue) => {
  const [state, setState] = React.useState(initialValue);
  React.useEffect(() => {
    const value = localStorage.getItem(key);
    if (value)
      setState(
        typeof value === "string"
          ? value === "true" || value === "false"
            ? JSON.parse(value)
            : value
          : JSON.parse(value)
      );
  }, [key]);

  React.useEffect(() => {
    localStorage.setItem(
      key,
      typeof state === "string" && (state !== "true" || state !== "false")
        ? state
        : JSON.stringify(state)
    );
  }, [state, key]);

  return [state, setState];
};

export default useLocalStorage;
