// extracted by mini-css-extract-plugin
export var dark = "styles-module--dark--3_Yeq";
export var ghost__cta = "styles-module--ghost__cta--2Je6E";
export var ghost__ctaButton = "styles-module--ghost__ctaButton--2QZ3Y";
export var ghost__ctaContent = "styles-module--ghost__ctaContent--1OYbL";
export var ghost__ctaDescription = "styles-module--ghost__ctaDescription--39PHF";
export var ghost__ctaTitle = "styles-module--ghost__ctaTitle--42dbs";
export var path = "styles-module--path--16RF9";
export var subscribe = "styles-module--subscribe--XuvwW";
export var subscribe__content = "styles-module--subscribe__content--O6lTF";
export var subscribe__title = "styles-module--subscribe__title--1iijG";
export var takeaways = "styles-module--takeaways--3JAR2";
export var takeaways__description = "styles-module--takeaways__description--1yL7X";
export var takeaways__list = "styles-module--takeaways__list--3AvJG";
export var takeaways__listItem = "styles-module--takeaways__listItem--3Y0A9";
export var takeaways__title = "styles-module--takeaways__title--1gqF1";
export var tip = "styles-module--tip--2pfz2";
export var tip__content = "styles-module--tip__content--C5RyA";
export var tip__icon = "styles-module--tip__icon--35FIS";
export var tip__title = "styles-module--tip__title--AROW2";
export var tip__titleHolder = "styles-module--tip__titleHolder--13KeI";