import {StyleExtractor} from "@kubric/utils";
import React from "react";

import * as styles from "./styles.module.scss";

export const Subscribe = ({
  title = "Access 350+ ready to use research Questions",
  children,
  themes,
}) => {
  const styler = new StyleExtractor(styles, themes);

  return (
    <div className={styler.get(["subscribe"])}>
      <h3 className={styler.get("subscribe__title")}>{title}</h3>
      <div className={styler.get("subscribe__content")}>{children}</div>
    </div>
  );
};
