import {OutboundLink} from "gatsby-plugin-google-analytics";
import React from "react";

import {
  FacebookIcon,
  InstagramIcon,
  LinkedinIcon,
  TwitterIcon,
  YoutubeIcon,
} from "../icons/ShareIcons";
import "./ShareTray.scss";

const DEFAULT = ["facebook", "instagram", "linkedin", "twitter", "youtube"];

const ShareTray = ({
  className,
  defaultColor = "#BFC2C9",
  show = DEFAULT,
  shareArticle = [],
  shareData = {},
  customUrl = {},
}) => {
  return (
    <div className={`flex items-center ${className}`}>
      {show.includes("facebook") && (
        <OutboundLink
          target='_blank'
          href={
            shareArticle.includes("facebook")
              ? `https://www.facebook.com/sharer.php?u=${shareData?.url}`
              : customUrl?.facebook || "https://www.facebook.com/getmason.io"
          }
          rel='noopener noreferrer'
        >
          <FacebookIcon color={defaultColor} />
        </OutboundLink>
      )}
      {show.includes("instagram") && (
        <OutboundLink
          target='_blank'
          href={
            customUrl?.instagram || "https://www.instagram.com/getmasondotio/"
          }
          rel='noopener noreferrer'
        >
          <InstagramIcon color={defaultColor} />
        </OutboundLink>
      )}
      {show.includes("linkedin") && (
        <OutboundLink
          target='_blank'
          href={
            shareArticle.includes("facebook")
              ? `https://www.linkedin.com/shareArticle?url=${shareData?.url}&title=${shareData?.title}`
              : customUrl?.linkedin ||
                "https://www.linkedin.com/company/get-mason"
          }
          rel='noopener noreferrer'
        >
          <LinkedinIcon color={defaultColor} />
        </OutboundLink>
      )}
      {show.includes("twitter") && (
        <OutboundLink
          target='_blank'
          href={
            shareArticle.includes("twitter")
              ? `https://twitter.com/share?url=${shareData?.url}&text=${shareData?.title}`
              : customUrl?.twitter || "https://twitter.com/getmasondotio"
          }
          rel='noopener noreferrer'
        >
          <TwitterIcon color={defaultColor} />
        </OutboundLink>
      )}
      {show.includes("youtube") && (
        <OutboundLink
          target='_blank'
          href={
            customUrl?.facebook ||
            "https://www.youtube.com/channel/UCrSfRxV5gmEmeb-iiyU6Vnw"
          }
          rel='noopener noreferrer'
        >
          <YoutubeIcon color={defaultColor} />
        </OutboundLink>
      )}
    </div>
  );
};

export default ShareTray;
