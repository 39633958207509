import * as React from "react";

export const useWindowDimensions = () => {
  const [windowDimensions, setWindowDimensions] = React.useState({
    width: 0,
    height: 0,
  });

  // setting current window dimensions
  React.useEffect(() => {
    const {innerWidth: width, innerHeight: height} = window;
    setWindowDimensions({width, height});
  }, []);

  // updating window dimensions on resize
  React.useEffect(() => {
    const handleResize = () => {
      const {innerWidth: width, innerHeight: height} = window;
      setWindowDimensions({width, height});
    };

    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, [windowDimensions.width, windowDimensions.height]);

  return windowDimensions;
};
