import {StyleExtractor} from "@kubric/utils";
import {OutboundLink} from "gatsby-plugin-google-analytics";
import React from "react";

import * as styles from "./styles.module.scss";

export const GhostCTA = ({
  title = "Try Modemagic for free",
  description = "Content toolbox for Ecommerce Stores.",
  button = "Sign up now",
  url,
  themes,
}) => {
  const styler = new StyleExtractor(styles, themes);

  return (
    <div className={styler.get(["ghost__cta"])}>
      <div className={styler.get(["ghost__ctaContent"])}>
        <h3 className={styler.get("ghost__ctaTitle")}>{title}</h3>
        <p className={styler.get("ghost__ctaDescription")}>{description}</p>
      </div>
      <OutboundLink className={styler.get(["ghost__ctaButton"])} href={url}>
        {button}
      </OutboundLink>
    </div>
  );
};
