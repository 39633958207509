import {StyleExtractor} from "@kubric/utils";
import React from "react";

import * as styles from "./styles.module.scss";

export const Takeaways = ({
  title = "Key Takeaways",
  theme = "light",
  description,
  children,
  themes,
}) => {
  const styler = new StyleExtractor(styles, themes);
  const takeaways = children?.map((child) => {
    const {props: {children: childContent} = {}} = child;
    return childContent;
  });

  return (
    <div
      className={styler.get([
        "takeaways",
        theme === "light" ? "light" : "dark",
      ])}
    >
      <p className={styler.get("takeaways__description")}>
        <span className={styler.get("takeaways__title")}>{title}: </span>
        {description}
      </p>
      <ul className={styler.get("takeaways__list")}>
        {takeaways.map((takeaway) => {
          return (
            <li className={styler.get("takeaways__listItem")} key={takeaway}>
              {takeaway}
            </li>
          );
        })}
      </ul>
    </div>
  );
};
