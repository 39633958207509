import {StyleExtractor} from "@kubric/utils";
import React from "react";

import {TipIcon} from "../../../../components/icons/TipIcon";
import * as styles from "./styles.module.scss";

export const Tip = ({theme = "light", title = "Protip", children, themes}) => {
  const styler = new StyleExtractor(styles, themes);

  return (
    <div className={styler.get(["tip", theme === "light" ? "light" : "dark"])}>
      <div className={styler.get("tip__titleHolder")}>
        <TipIcon theme={theme} className={styler.get("tip__icon")} />
        <h3 className={styler.get("tip__title")}>{title}</h3>
      </div>
      <div className={styler.get("tip__content")}>{children}</div>
    </div>
  );
};
